import {
  Alert,
  Anchor,
  Button,
  Card,
  Group,
  LoadingOverlay,
  NativeSelect,
  PasswordInput,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import {
  hideNotification,
  showNotification,
  updateNotification,
} from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { RegistrationService } from "sublinx-autopilot-shared/src/services/RegistrationService";
import * as Yup from "yup";
import { useAuth } from "../../../auth/useAuth";
import { StreetAddressFormComponents } from "../../../components/form/StreetAddressFormComponents";

const schema = Yup.object().shape({
  name: Yup.string().required("Company Name is required"),
  tradeId: Yup.string().required("Trade is required"),
  contact: Yup.object().shape({
    givenName: Yup.string().required("First Name is required"),
    familyName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    username: Yup.string().required("Username is required"),
    officePhone: Yup.string().required("Office Phone is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirmation password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  }),
});

export const SubcontractorRegistration: FC = () => {
  const [searchParams] = useSearchParams();
  const authUser = useAuth();
  useEffect(() => {
    if (authUser.authUser) {
      navigate(`/administration/builders?code=${searchParams.get("code")}`);
    }
  }, [authUser]);
  const subQuery = useQuery({
    queryKey: ["sub", searchParams.get("code")],
    queryFn: () =>
      RegistrationService.findSubByConnectCode(
        searchParams.get("code") || undefined,
      ),
    enabled: !!searchParams.get("code"),
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const form = useForm({
    validate: yupResolver(schema),
    validateInputOnChange: true,
    initialValues: {
      name: subQuery?.data?.name || "",
      connectCode: searchParams.get("code") || "",
      tradeId: subQuery?.data?.trade?.id || "",
      address: {
        street1: subQuery?.data?.address?.street1 || "",
        street2: subQuery?.data?.address?.street2 || "",
        city: subQuery?.data?.address?.city || "",
        state: subQuery?.data?.address?.state || "",
        postalCode: subQuery?.data?.address?.postalCode || "",
      },
      contact: {
        givenName: subQuery?.data?.primaryContact?.givenName || "",
        familyName: subQuery?.data?.primaryContact?.familyName || "",
        email: "",
        username: "",
        phone: "",
        notes: "",
        password: "",
        confirmPassword: "",
      },
    },
  });

  useEffect(() => {
    form.setValues({
      ...form.values,
      name: subQuery?.data?.name || "",
      tradeId: subQuery?.data?.trade?.id || "",
      address: {
        street1: subQuery?.data?.address?.street1 || "",
        street2: subQuery?.data?.address?.street2 || "",
        city: subQuery?.data?.address?.city || "",
        state: subQuery?.data?.address?.state || "",
        postalCode: subQuery?.data?.address?.postalCode || "",
      },
      contact: {
        givenName: subQuery?.data?.primaryContact?.givenName || "",
        familyName: subQuery?.data?.primaryContact?.familyName || "",
        email: subQuery.data?.primaryContact?.email || "",
        username: subQuery.data?.primaryContact?.email || "",
        phone: "",
        notes: "",
        password: "",
        confirmPassword: "",
      },
    });
  }, [subQuery.data]);

  //   const { data: trades } = useQuery({
  //     queryKey: ['trades'],
  //     queryFn: AdminService.trades.fetchAll,
  //   });
  const handleSubmit = async (values: typeof form.values) => {
    setIsLoading(true);
    form.resetDirty();
    showNotification({
      id: "manageSubNotification",
      autoClose: false,
      loading: true,
      title: "Saving",
      message: "Please wait...",
    });

    RegistrationService.createSub({
      ...values,
      subOrgEntity: {
        ...values,
        tradeId: Number(values.tradeId),
        address: {
          ...values.address,
        },
        // contact: {
        //   ...values.contact,
        // }, No longer expecting this
      },
      subUserEntity: {
        ...values.contact,
        email: values?.contact.email,
        username: values?.contact.username,
        givenName: values?.contact.givenName,
        familyName: values?.contact.familyName,
        enabled: true,
        password: values?.contact.password,
      },
    })
      .then(() => {
        updateNotification({
          id: "manageSubNotification",
          autoClose: true,
          loading: false,
          title: "Save Successful",
          message: "Sub saved successfully",
          icon: <IconCheck />,
          color: "green",
        });
        // if (sub?.id) {
        //   refetchSub();
        // } else {
        //   navigate(`/admin/subcontractors/${updatedSub?.id}`);
        // }
        navigate("/");
      })
      .catch(() => {
        // updateNotification({
        //   id: 'manageSubNotification',
        //   loading: false,
        //   title: 'Error while saving',
        //   message: e.message,
        //   icon: <IconExclamationMark />,
        //   color: 'red',
        // });
        hideNotification("manageSubNotification");
        setIsLoading(false);
      })
      .finally(async () => {
        setIsLoading(false);
      });
  };

  //   if (!trades) {
  //     return <SuspenseLoader />;
  //   }

  return (
    <>
      {subQuery.data?.connectedSubId ? (
        <>
          <Alert title="Code Registered" color="red" mb="sm">
            <Text size="sm">
              This code has already been registered to an account. Please login
              by clicking{" "}
              <Anchor component={Link} to="/login}">
                here
              </Anchor>
              .
            </Text>
          </Alert>
        </>
      ) : (
        <>
          <Alert
            title="You've been invited to join Autopilot"
            color="blue"
            mb="sm"
          >
            <Text size="sm">
              You've been invited to join Autopilot as a subcontractor. Please
              complete the form below to register your company.
            </Text>
            <Text size="sm">
              If you already have an account you can login by clicking{" "}
              <Anchor
                component={Link}
                to={`/administration/builders?connectCode=${searchParams.get("code")}`}
              >
                here
              </Anchor>
              .
            </Text>
          </Alert>
          <form
            onSubmit={form.onSubmit((values) => handleSubmit(values))}
            noValidate
            autoComplete="off"
          >
            <Card>
              <Card.Section withBorder inheritPadding py="md">
                <Text fw="bold">Subcontractor Registration</Text>
              </Card.Section>
              <Card.Section inheritPadding mb="md" py="md">
                <LoadingOverlay
                  loaderProps={{ variant: "bars" }}
                  visible={isLoading}
                  overlayProps={{
                    blur: 5,
                    opacity: 0.5,
                  }}
                />

                <Stack>
                  <SimpleGrid cols={2}>
                    <TextInput
                      data-testid="name-input"
                      autoFocus
                      disabled={isLoading}
                      required
                      label="Company Name"
                      placeholder="Enter Company name"
                      {...form.getInputProps("name")}
                    />
                    <NativeSelect
                      data={
                        subQuery.data?.trade?.name
                          ? [
                              {
                                label: subQuery.data?.trade?.name!,
                                value: subQuery.data?.trade?.id?.toString()!,
                              },
                            ]
                          : []
                      }
                      value={subQuery.data?.trade?.id || ""}
                      data-testid="trade-select"
                      label="Trade"
                      required
                      disabled={
                        isLoading || subQuery.data?.trade?.name !== undefined
                      }
                      {...form.getInputProps("tradeId")}
                    />
                  </SimpleGrid>
                  <StreetAddressFormComponents
                    form={form}
                    required={false}
                    isLoading={isLoading}
                  />
                  <Title order={5}>Primary User</Title>
                  <Stack gap="sm">
                    <SimpleGrid mt={0} cols={{ base: 2, sm: 1, md: 2 }}>
                      <TextInput
                        data-testid="first-name-input"
                        disabled={isLoading}
                        required
                        label="First Name"
                        placeholder="Enter first name"
                        {...form.getInputProps("contact.givenName")}
                      />
                      <TextInput
                        data-testid="last-name-input"
                        disabled={isLoading}
                        required
                        label="Last Name"
                        placeholder="Enter last name"
                        {...form.getInputProps("contact.familyName")}
                      />
                    </SimpleGrid>
                    <SimpleGrid mt={0} cols={{ base: 2, sm: 1, md: 2 }}>
                      <TextInput
                        data-testid="email-input"
                        disabled={isLoading}
                        label="Email"
                        required
                        type="email"
                        placeholder="Enter email"
                        {...form.getInputProps("contact.email")}
                      />
                      <TextInput
                        data-testid="username-input"
                        disabled={isLoading}
                        required
                        label="Username"
                        placeholder="Enter username"
                        {...form.getInputProps("contact.username")}
                      />
                    </SimpleGrid>
                    <SimpleGrid mt={0} cols={{ base: 2, sm: 1, md: 2 }}>
                      <TextInput
                        data-testid="office-phone-input"
                        disabled={isLoading}
                        type="phone"
                        label="Office Phone"
                        placeholder="Enter office phone"
                        {...form.getInputProps("contact.officePhone")}
                      />
                      <TextInput
                        data-testid="mobile-phone-input"
                        disabled={isLoading}
                        label="Mobile Phone"
                        type="phone"
                        placeholder="Enter mobile phone"
                        {...form.getInputProps("contact.mobilePhone")}
                      />
                    </SimpleGrid>
                  </Stack>
                  <SimpleGrid cols={2}>
                    <PasswordInput
                      data-testid="password-input"
                      label="Password"
                      required
                      placeholder="Password"
                      {...form.getInputProps("contact.password")}
                    />
                    <PasswordInput
                      data-testid="confirm-password-input"
                      label="Confirm Password"
                      required
                      placeholder="Confirm Password"
                      {...form.getInputProps("contact.confirmPassword")}
                    />
                  </SimpleGrid>
                </Stack>
              </Card.Section>
              <Card.Section withBorder inheritPadding py="sm">
                <Group justify="space-between">
                  <Button
                    component={Link}
                    to="/admin/subcontractors"
                    disabled={isLoading}
                    type="button"
                    variant="subtle"
                  >
                    {t("common.cancel")}
                  </Button>
                  <Button
                    data-testid="submit-button"
                    disabled={isLoading}
                    type="submit"
                  >
                    {t("common.save")}
                  </Button>
                </Group>
              </Card.Section>
            </Card>
          </form>
        </>
      )}
    </>
  );
};
